import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "./Navbar.css";
import axios from "axios";
import { myConfig } from "../../config.js";
import moment from "moment";

export default function Navbar() {
  const [showNotification, setShowNotification] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");
  const apiURL = myConfig.apiUrl;
  const [count, setCount] = useState("0");
  const hostname = window.location.origin;
  const notificationpopup = useRef(null);

  const logoutFn = () => {
    cookies.remove("accessToken");
    cookies.remove("customerAccId");
    cookies.remove("expTime");
    localStorage.removeItem("auth");
    window.location.href = `${hostname}/`;
  };

  const handleWrapperClick = (e) => {
    if (
      notificationpopup.current &&
      !notificationpopup.current.contains(e.target)
    ) {
      setShowNotification(false);
    }
  };

  document.addEventListener("mousedown", handleWrapperClick);

  const notification = async () => {
    setCount("");
    setShowNotification(!showNotification);
    try {
      const putRequestData = {
        customerId: cookies.get("customerAccId"),
      };
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      const updateNotificationResponse = await axios.put(
        `${apiURL}/AppNotification/UpdateAppNotificationStatus_ByCustomer`,
        putRequestData
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const getAlerts = async () => {
      try {
        const customerId = {
          customerId: cookies.get("customerAccId"),
        };
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        const notificationResponse = await axios.post(
          `${apiURL}/AppNotification/GetAppNotification`,
          customerId
        );
        if (notificationResponse.data.appNotificationsList.length > 0) {
          setData(notificationResponse.data.appNotificationsList);
          setCount(notificationResponse.data.appNotificationCount);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getAlerts();
    const interval = setInterval(() => getAlerts(), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const convertTime = (x) => {
    const utcTime = x.split(" ")[1];
    const utcDateTimeString = `1970-01-01T${utcTime}Z`;

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "America/Los_Angeles", // Pacific Time Zone
      hour12: true,
    };

    const pstTime = new Date(utcDateTimeString).toLocaleString(
      "en-US",
      options
    );

    return pstTime;
  };
  const convertDateYear = (x) => {
    const utcDateString = x.split(" ")[0];
    const utcTimex = x.split(" ")[1];
    const utcDateTimeString = `${utcDateString} ${utcTimex} UTC`;

    const utcDate = new Date(utcDateTimeString);
    const pstDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    const month = (pstDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = pstDate.getDate().toString().padStart(2, "0");
    const year = pstDate.getFullYear();
    const formattedDate = month + "/" + day + "/" + year;
    return formattedDate;
  };

  return (
    <div>
      <div className="navbar-welcome">
        <span className="welcome_tag">Welcome</span>
        {", "}
        <span className="welcome_text">{cookies.get("customerAccId")}</span>
      </div>
      <div className="navbar-component">
        <div className="header-image" onClick={() => navigate("/home")}>
          <img
            src={require("../../assets/header-logo.png")}
            alt="header logo"
          />
        </div>
        <div className="header-icons">
          <div className="navbar-icons">
            <ul
              className="icons"
              ref={notificationpopup}
              onClick={handleWrapperClick}
            >
              <li
                onClick={() => navigate("/home")}
                className={
                  window.location.pathname === "/home" ? "active" : "inactive"
                }
              >
                <img
                  src={require("../../assets/Home.png")}
                  alt="Navbar Icons"
                />
              </li>
              <li
                onClick={() => navigate("/product")}
                className={
                  window.location.pathname === "/product"
                    ? "active"
                    : "inactive"
                }
              >
                <img
                  src={require("../../assets/Product list.png")}
                  alt="Navbar Icons"
                />
              </li>
              <li
                onClick={() => notification()}
                className={showNotification ? "active" : "inactive"}
              >
                <img
                  src={require("../../assets/Alert.png")}
                  alt="Navbar Icons"
                />
                {count > 0 && <div className="statusCount">{count}</div>}
              </li>
              <li
                onClick={() => navigate("/mydownloads")}
                className={
                  window.location.pathname === "/mydownloads"
                    ? "active"
                    : "inactive"
                }
              >
                <img
                  src={require("../../assets/Download.png")}
                  alt="Navbar Icons"
                />
              </li>
              <li onClick={logoutFn}>
                <img
                  src={require("../../assets/logout.png")}
                  alt="Navbar Icons"
                />
              </li>
            </ul>
          </div>
        </div>
        {showNotification && (
          <>
            <div
              className="notifications"
              ref={notificationpopup}
              onClick={handleWrapperClick}
            >
              <div className="notification-box">
                <div className="notifications-title">Notifications</div>
                <div className="notifications-listitems">
                  {data?.map((item, index) => (
                    <div className="notifications-list" key={index}>
                      <div className="desc">
                        <div className="desc1">
                          Your File is Ready For Download. Go To{" "}
                          <span
                            className="mydownloads"
                            onClick={() =>
                              (window.location.href = "/mydownloads")
                            }
                          >
                            My Downloads
                          </span>
                          <br />
                          To Download The File.
                        </div>
                        {index === 0 && (
                          <div className="newStatus">
                            {item.download_status.toLowerCase() === "new" && (
                              <span className="new-status">NEW</span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="notification-date">
                        {/* {moment(item.createdDate).format("MM/DD/YYYY, hh:mm A")} */}
                        {convertDateYear(item.createdDate) + ", "}
                        {convertTime(item.createdDate)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
