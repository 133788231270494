import React, { Component } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import isEqual from "lodash/isEqual";
// import { TarifForTreeSelect } from "interfaces/TarifForTreeSelect";
import "react-dropdown-tree-select/dist/styles.css";

class TarifsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      value: props.value,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
    if (!isEqual(nextProps.value, this.state.value)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.data, this.state.data);
  };

  render() {
    const onChangeTemp = (currentNode, selectedNodes) => {
      this.props.onChange(currentNode, selectedNodes);
    };

    return (
      <>
        <DropdownTreeSelect
          className="mdl-demo"
          data={
            this.state.data && this.state.data.value !== ""
              ? this.state.data
              : []
          }
          onChange={onChangeTemp}
          texts={{ placeholder: "Search..." }}
          showPartiallySelected
          ref={this.props.ref}
          value={this.state.value}
          // selectedNodes={[]}
        />
      </>
    );
  }
}

export default React.memo(TarifsSelector);
