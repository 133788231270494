import { useEffect } from "react";
import Cookies from "universal-cookie";
import { myConfig } from "./config";
import axios from "axios";
const useTokenExpiration = () => {
  const tokenUrl = myConfig.tokenUrl;
  const client_id = myConfig.client_id;
  const client_secret = myConfig.client_secret;

  useEffect(() => {
    const cookies = new Cookies();
    setInterval(() => {
      let expTime = cookies.get("expTime");
      if (expTime) {
        if (new Date() > new Date(expTime)) {
          const headers = {
            "Content-Type": "application/json",
          };

          axios
            .post(
              tokenUrl,
              {
                client_id: client_id,
                client_secret: client_secret,
              },
              { headers }
            )
            .then((response) => {
              cookies.set("accessToken", response.data.access_token);
              cookies.set("expSeconds", response.data.expires_in);
              let expTime = new Date();
              expTime.setSeconds(
                expTime.getSeconds() + response.data.expires_in - 100
              );
              cookies.set("expTime", expTime);
            })
            .catch((error) => {
              cookies.remove("expTime");
              cookies.remove("accessToken");
              cookies.remove("customerAccId");
              localStorage.removeItem("auth");
              const hostname = window.location.origin;
              window.location.href = `${hostname}/`;
            });
        }
      } else {
        let p = window.location.href.split("/").pop();
        if (p !== "login") {
          cookies.remove("expTime");
          cookies.remove("accessToken");
          cookies.remove("customerAccId");
          localStorage.removeItem("auth");
          const hostname = window.location.origin;
          window.location.href = `${hostname}/`;
        }
      }
    }, 1000);
  }, []);
};

export default useTokenExpiration;
