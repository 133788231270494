import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Product from "./pages/Product/productListing";
import Mydownloads from "./pages/Mydownloads/Mydownloads";
import useTokenExpiration from "./useTokenExpiration";
import Cookies from "universal-cookie";

function App() {
  const cookies = new Cookies();

  //Token Expiration logic
  useTokenExpiration();
  //Inactivity  logic
  const LogoutTimer = ({ timeoutMinutes }) => {
    const [lastActivity, setLastActivity] = useState(Date.now());

    useEffect(() => {
      const resetTimer = () => {
        setLastActivity(Date.now());
      };

      const checkInactivity = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivity;
        const timeoutMilliseconds = timeoutMinutes * 1000;

        if (elapsedTime >= timeoutMilliseconds) {
          // Trigger logout action
          cookies.remove("expTime");
          cookies.remove("expSeconds");
          cookies.remove("accessToken");
          cookies.remove("customerAccId");
          localStorage.removeItem("auth");
          const hostname = window.location.origin;
          window.location.href = `${hostname}/`;
        }
      };

      document.addEventListener("click", resetTimer);

      const checkInterval = setInterval(checkInactivity, 1000); // Check every second
      return () => {
        document.removeEventListener("click", resetTimer);
        clearInterval(checkInterval);
      };
    }, [lastActivity, timeoutMinutes]);

    return (
      <div>{/* Render anything you want, or you can omit this part */}</div>
    );
  };

  // Unauthenticated users redirected to log in route
  const ProtectedRoute = ({ isAuthenticated }) => {
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
  };

  // Authenticated users redirected to safe route
  const AnonymousRoute = ({ isAuthenticated }) => {
    return isAuthenticated ? <Navigate to="/home" replace /> : <Outlet />;
  };
  // Initialize state from localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return JSON.parse(localStorage.getItem("auth")) ?? false;
  });

  // Side-effect to persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <div>
      <LogoutTimer timeoutMinutes={cookies.get("expSeconds")} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />

          <Route element={<AnonymousRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
          </Route>

          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/home" element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/mydownloads" element={<Mydownloads />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
