import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import "./style.css";

export default function CustomizedBreadcrumbs() {
  const navigate = useNavigate();
  
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" onClick={() => navigate("/home")}className="homeLink">
          <HomeIcon sx={{ mr: 0.2 }} fontSize="inherit" />
          Home
        </Link>
        <Link underline="hover" className="productLink">
          Products
        </Link>
      </Breadcrumbs>
    </div>
  );
}
