import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Pagination } from "@mui/material";
import CustomizedBreadcrumbs from "../Breadcrumb/breadcrumb";
import Button from "@mui/material/Button";
import Navbar from "../Navbar/Navbar";
import Cookies from "universal-cookie";
import axios from "axios";
import { myConfig } from "../../config.js";
import Spinner from "../Spinner/spinner.js";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import Tarif from "./tarif";

import ProductImage from "../../../src/assets/notfoungimg.png";
export default function Product() {
  const navigate = useNavigate();
  const apiURL = myConfig.apiUrl;
  const [jsonData, setJsonData] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [brand, setBrand] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [checked, setChecked] = useState({});
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchBulkDownload, setSearchBulkDownload] = useState(null);
  const [isDownloadBtnDisable, setIsDownloadBtnDisable] = useState(true);
  const [open, setOpen] = React.useState(false);
  const count = Math.ceil(totalRecordCount / pageSize);
  const [isCheckboxSelectorDisabled, setIsCheckboxSelectorDisabled] =
    useState(false);

  const [brandArray, setbrandarray] = useState([]);
  const [categoryArray, setcategoryArray] = useState([]);
  const [supplierArray, setsupplierArray] = useState([]);
  const [bulkDownloadPopup, setbulkDownloadPopup] = useState(false);
  const [downloadAlert, setDownloadAlert] = useState(false);
  const [searchDataParams, setsearchDataParams] = useState(null);
  const [checkArray, setCheckArray] = useState([]);
  const [ModifiedOn, setModifiedOn] = useState("");
  const [isValidDate, setValidDate] = useState("");
  const [dateMessage, setDateMessage] = useState("");
  const [SKUError, setSKUError] = useState(false);
  const [Applybutton, setApplybutton] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handledateChange = (e) => {
    setModifiedOn(e.target.value);
  };

  const handleBlurDate = (e) => {
    const enteredDate = new Date(e.target.value);
    if (isNaN(enteredDate.getTime())) {
      setDateMessage("Please enter a valid date format (MM/DD/YYYY)");
      setValidDate("Invalid");
    }
    if (enteredDate > new Date()) {
      setValidDate("Future");
      setDateMessage("Please select a date on or before today");
    }
    if (enteredDate < new Date() && isNaN(enteredDate.getTime()) === false) {
      setValidDate("");
      setDateMessage("");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (checkArray.length > 0) {
      setIsDownloadBtnDisable(false);
    } else {
      setIsDownloadBtnDisable(true);
    }
  }, [checkArray]);

  const clearAllFn = async () => {
    setsearchDataParams(null);
    window.location.reload();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 365,
    height: 176,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };

  const handleChecked = (key, checked) => {
    let carray = checkArray;
    if (checked === true) {
      carray.push(key);
      setCheckArray([...carray]);
    } else {
      const newArray = carray.filter((item) => item !== key);
      setCheckArray([...newArray]);
    }
  };

  const toggleCheckboxes = (e) => {
    setIsCheckedAll(e);
    if (e === true) {
      let carray = checkArray;
      jsonData.forEach((x) => {
        let found = carray.find((y) => y === x.supplierSKU);
        if (found === undefined) {
          carray.push(x.supplierSKU);
        }
      });
      setCheckArray([...carray]);
    } else {
      let newArray = [];
      let carray = checkArray;
      jsonData.forEach((x) => {
        newArray = carray.filter((item) => item !== x.supplierSKU);
        carray = newArray;
        setCheckArray([...newArray]);
      });
    }
  };

  const handlePageChange = (pageNumber, status) => {
    setCurrentPage(pageNumber);
    const productListData = async () => {
      setDataLoading(status);
      try {
        const getProductpostData = {
          customerAccountId: cookies.get("customerAccId"),
          ModifiedOn: status ? ModifiedOn : "",
          PageNo: pageNumber,
          includeInventory: "y",
          search: status ? searchDataParams : null,
        };
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        const productListResponse = await axios.post(
          `${apiURL}/ProductList/Display-product-details`,
          getProductpostData
        );
        setJsonData(productListResponse.data.productListResponse);
        setPageSize(parseInt(productListResponse.data.dataPerPage));
        setTotalRecordCount(parseInt(productListResponse.data.totalRecords));
        if (productListResponse.data.productListResponse.length === 0) {
          setStatusMessage("No records found");
        } else {
          setStatusMessage("");
        }
      } catch (error) {
        setCurrentPage(1);
        setJsonData([]);
        setStatusMessage("Unable to retrieve products. Please try again later");
      } finally {
        setDataLoading(false);
        setIsCheckedAll(false);
      }
    };
    productListData();

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    handlePageChange(1, true);
  }, []);

  const onChangeCategory = (currentNode, selectedNodes) => {
    const catarr = selectedNodes;
    const categoryarr = catarr
      .filter(({ value }) => typeof value !== "undefined")
      .map(({ value }) => value);
    setcategoryArray([...categoryarr]);
  };

  const onChangeSupplier = (currentNode, selectedNodes) => {
    const supplierarr = selectedNodes;
    const supplyarray = supplierarr
      .filter(({ label }) => typeof label !== "undefined")
      .map(({ label }) => label);
    setsupplierArray([...supplyarray]);
  };

  const onChangeBrand = (currentNode, selectedNodes) => {
    const brandarr = selectedNodes;
    const brandarray = brandarr
      .filter(({ label }) => typeof label !== "undefined")
      .map(({ label }) => label);
    setbrandarray([...brandarray]);
  };

  const handleApplyButtonClick = () => {
    let searchCategoryData = {
      attributeCode: "category",
      attributevalue: {
        value1: categoryArray.join("$"),
        value2: "",
      },
    };

    let searchSKUData = {
      attributeCode: "sku",
      attributevalue: {
        value1: from,
        value2: to,
      },
    };

    let searchSupplierData = {
      attributeCode: "supplier",
      attributevalue: {
        value1: supplierArray.join("$"),
        value2: "",
      },
    };

    let searchBrandData = {
      attributeCode: "brand",
      attributevalue: {
        value1: brandArray.join("$"),
        value2: "",
      },
    };

    const supplierBrandSearchData = [];
    if (searchCategoryData.attributevalue.value1) {
      supplierBrandSearchData.push(searchCategoryData);
    }
    if (searchSupplierData.attributevalue.value1) {
      supplierBrandSearchData.push(searchSupplierData);
    }

    if (searchBrandData.attributevalue.value1) {
      supplierBrandSearchData.push(searchBrandData);
    }
    if (
      searchSKUData.attributevalue.value1 &&
      searchSKUData.attributevalue.value2
    ) {
      supplierBrandSearchData.push(searchSKUData);
      setSKUError(false);
    } else {
      setSKUError(true);
    }
    if (
      searchSKUData.attributevalue.value1 === "" &&
      searchSKUData.attributevalue.value2 === ""
    ) {
      setSKUError(false);
    }

    if (supplierBrandSearchData.length > 0 && isValidDate !== "Future") {
      setSearchBulkDownload(supplierBrandSearchData);
      searchAPIFn(supplierBrandSearchData);
      setApplybutton(false);
      setValidDate("");
    }
    if (supplierBrandSearchData.length > 0 && isValidDate === "Invalid") {
      setSearchBulkDownload(supplierBrandSearchData);
      searchAPIFn(supplierBrandSearchData);
      setApplybutton(false);
      setValidDate("");
      setModifiedOn(null);
    }
    if (supplierBrandSearchData.length === 0 && ModifiedOn === "") {
      setApplybutton(true);
      setValidDate("");
      setModifiedOn(null);
    }
    if (
      supplierBrandSearchData.length === 0 &&
      ModifiedOn &&
      isValidDate !== "Future"
    ) {
      setSearchBulkDownload(supplierBrandSearchData);
      searchAPIFn(supplierBrandSearchData);
      setApplybutton(false);
    }
  };

  const searchAPIFn = (searchData) => {
    setsearchDataParams(searchData);
    setIsSearch(true);
    setDataLoading(true);
    const postProductpostData = {
      customerAccountId: cookies.get("customerAccId"),
      ModifiedOn: ModifiedOn,
      PageNo: 1,
      search: searchData.length === 0 ? null : searchData,
    };
    axios
      .post(
        `${apiURL}/ProductList/Display-product-details`,
        postProductpostData,
        {
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
            Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header
          },
        }
      )
      .then((response) => {
        setCurrentPage(1);
        setJsonData(response.data.productListResponse);
        setPageSize(parseInt(response.data.dataPerPage));
        setTotalRecordCount(parseInt(response.data.totalRecords));
        window.scrollTo(0, 0);
        setDataLoading(false);
        if (response.data.productListResponse.length === 0) {
          setStatusMessage("No records found");
        } else {
          setStatusMessage("");
        }
      })
      .catch((error) => {
        setCurrentPage(1);
        setJsonData([]);
        setStatusMessage("Unable to retrieve products. Please try again later");
        setDataLoading(false);
      });
  };

  useEffect(() => {
    const facetsFilterData = async () => {
      try {
        const getCategoryPostData = {
          customerAccountId: cookies.get("customerAccId"),
        };
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        const categoryResponse = axios.post(
          `${apiURL}/ProductList/Get-category`,
          getCategoryPostData
        );
        const supplierBrandResponse = axios.post(
          `${apiURL}/ProductList/Get-customerfacets`
        );

        const responses = await Promise.all([
          categoryResponse,
          supplierBrandResponse,
        ]);
        setCategory(responses[0].data.data[0].children);
        setSupplier(responses[1].data.supplier);
        setBrand(responses[1].data.brand);
      } catch (error) {
      } finally {
        //setLoading(false);
      }
    };

    facetsFilterData();
  }, []);

  const handleDownloadALL = () => {
    // filterClear();
    setbulkDownloadPopup(false);
    setOpen(true);
    const postData = {
      customerId: cookies.get("customerAccId"),
      ModifiedOn: ModifiedOn,
      partNumber: null,
      isSelected: true,
      isSearch: isSearch,
      search: searchBulkDownload,
    };
    axios
      .post(`${apiURL}/Download/product-download`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response) {
          setLoading(false);
        } else {
          console.error("Failed to download");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  function filterClear() {
    handlePageChange(1, false);
    let cat = category;
    let sup = supplier;
    let bra = brand;
    setCategory([]);
    setSupplier([]);
    setBrand([]);
    setbrandarray([]);
    setcategoryArray([]);
    setsupplierArray([]);
    setTimeout(() => {
      setCategory([...cat]);
      setSupplier([...sup]);
      setBrand([...bra]);
    }, 100);
    setCheckArray([]);
    setTo("");
    setFrom("");
    setModifiedOn("");
  }

  const handleDownload = () => {
    setsearchDataParams(null);
    filterClear();
    setDownloadAlert(false);
    setOpen(true);
    const postData = {
      customerId: cookies.get("customerAccId"),
      ModifiedOn: ModifiedOn,
      partNumber: checkArray.length === 0 ? null : checkArray,
      isSelected: true,
      isSearch: false,
      search: null,
    };
    axios
      .post(`${apiURL}/Download/product-download`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response) {
          setIsCheckedAll(false);
        } else {
          console.error("Failed to download");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="cancel-icon" onClick={() => handleClose()}>
            <img src={require("../../assets/Top.png")} alt="cancel icon" />
          </div>
          <div className="downloadInfo">
            "The download has been initiated. You will get a notification once
            the file is ready for download."
          </div>
        </Box>
      </Modal>
      <Modal
        open={bulkDownloadPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="cancel-icon"
            onClick={() => setbulkDownloadPopup(false)}
          >
            <img src={require("../../assets/Top.png")} alt="cancel icon" />
          </div>
          <div className="downloadInfo">
            {`Do you want to download the complete ${totalRecordCount} of products?`}
            <div className="bulk_dwd_btn">
              <Button
                onClick={() => setbulkDownloadPopup(false)}
                variant="outlined"
                style={{ textTransform: "capitalize" }}
              >
                No
              </Button>
              <Button
                onClick={handleDownloadALL}
                variant="contained"
                style={{ textTransform: "capitalize" }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={downloadAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="cancel-icon" onClick={() => setDownloadAlert(false)}>
            <img src={require("../../assets/Top.png")} alt="cancel icon" />
          </div>
          <div className="downloadInfo">
            {`Do you want to download the complete ${checkArray.length} of products?`}
            <div className="bulk_dwd_btn">
              <Button
                onClick={() => setDownloadAlert(false)}
                variant="outlined"
                style={{ textTransform: "capitalize" }}
              >
                No
              </Button>
              <Button
                onClick={handleDownload}
                variant="contained"
                style={{ textTransform: "capitalize" }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Navbar />
      <Divider variant="middle" sx={{ margin: "10px 0 0" }} />
      <div className="container">
        <Grid container sx={{ flexWrap: "nowrap" }}>
          <Grid item xs={2} sx={{ backgroundColor: "#F8F9FA" }}>
            <div className="filterContainer">
              <div className="filterTitle">Filters</div>
              <div className="clearAllTitle" onClick={clearAllFn}>
                Clear all
              </div>
            </div>
            <div className="categoryContainer">
              <div className="category-title">
                <h3>Category</h3>
              </div>
              <Tarif
                data={category}
                onChange={onChangeCategory}
                className="mdl-demo"
              />
            </div>
            <div className="sku-container">
              <h3 className="SKUTittle">Modified Since</h3>
              <div className="formFields">
                <TextField
                  value={ModifiedOn}
                  error={isValidDate ? true : false}
                  onBlur={handleBlurDate}
                  helperText={isValidDate && dateMessage}
                  onChange={handledateChange}
                  type="date"
                  size="small"
                />
              </div>
              <h3 className="SKUTittle">SKU</h3>

              <div className="formFields">
                <TextField
                  label="From"
                  variant="outlined"
                  size="small"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
                <TextField
                  style={{ marginTop: 3 }}
                  label="To"
                  variant="outlined"
                  value={to}
                  size="small"
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
            </div>
            {SKUError && (
              <div className="skuerror">
                Please enter a value in both the "From" and "To" fields
              </div>
            )}
            <div className="categoryContainer">
              <div className="category-title">
                <h3>Supplier</h3>
              </div>
              <Tarif
                data={supplier}
                onChange={onChangeSupplier}
                className="mdl-demo"
              />
            </div>
            <div className="categoryContainer">
              <div className="category-title">
                <h3>Brand</h3>
              </div>
              <Tarif
                data={brand}
                onChange={onChangeBrand}
                className="mdl-demo"
              />
            </div>
            <div className="ApplyButtonError">
              {Applybutton && (
                <span>
                  Please fill out at least one field before submitting the form.
                </span>
              )}
            </div>
            <div className="apply-btn">
              <Button
                variant="contained"
                className={"applygoButton"}
                onClick={handleApplyButtonClick}
              >
                Apply
              </Button>
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={10}>
            <div className="breadcrumbs">
              <CustomizedBreadcrumbs />
            </div>
            <div className="titleContainer">
              <div className="title">Products</div>
              <div className="downloadButton">
                {loading && <Spinner />}
                <Button
                  disabled={isDownloadBtnDisable}
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => setDownloadAlert(true)}
                >
                  Download
                </Button>
                <Button
                  onClick={() => setbulkDownloadPopup(true)}
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                >
                  Download All
                </Button>
              </div>
            </div>
            <div className="containerProductListing">
              <Divider
                variant="middle"
                sx={{ marginLeft: 0, marginRight: 0 }}
              />
              <br />
              <div className="pagination">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                      }}
                    >
                      Items per page: {pageSize}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Open Sans",
                    }}
                  >
                    Showing{" "}
                    {Math.min(
                      (currentPage - 1) * pageSize + 1,
                      totalRecordCount
                    )}{" "}
                    - {Math.min(currentPage * pageSize, totalRecordCount)} of{" "}
                    {totalRecordCount} results
                  </Typography>
                  <Box>
                    <Pagination
                      count={count}
                      variant="outlined"
                      color="primary"
                      shape="rounded"
                      onChange={(event, value) => handlePageChange(value, true)}
                      page={currentPage}
                    />
                  </Box>
                </Box>
              </div>

              <table className="orders-table">
                <thead>
                  <tr>
                    <th>
                      <div className="sku_head">
                        <div>
                          {jsonData.length > 0 && (
                            <input
                              className="checkboxselector"
                              type="checkbox"
                              checked={isCheckedAll}
                              onChange={(e) =>
                                toggleCheckboxes(e.target.checked)
                              }
                            ></input>
                          )}
                        </div>
                        <div style={{ width: 100 }}>Customer SKU</div>
                      </div>
                    </th>
                    <th>Supplier SKU</th>
                    <th>Product Name</th>
                    <th>Supplier</th>
                    <th>Brand</th>
                  </tr>
                </thead>
                <tbody>
                  {dataLoading && <Spinner />}
                  {jsonData?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: "10%",
                          whiteSpace: "nowrap",
                          fontSize: 12,
                        }}
                      >
                        <div className="sku_head">
                          <div>
                            <input
                              className="checkboxselector"
                              type="checkbox"
                              disabled={isCheckboxSelectorDisabled}
                              checked={checkArray.includes(item.supplierSKU)}
                              onChange={(e) =>
                                handleChecked(
                                  item.supplierSKU,
                                  e.target.checked
                                )
                              }
                            ></input>
                          </div>
                          <div>{item.customerSKU}</div>
                        </div>
                      </td>
                      <td style={{ width: "10%", fontSize: 12 }}>
                        {item.supplierSKU}
                      </td>
                      <td style={{ width: "50%" }}>
                        <div className="productStyle">
                          <img
                            src={
                              item.productImageUrl
                                ? item.productImageUrl
                                : ProductImage
                            }
                            style={{ width: "56px", height: "56px" }}
                            alt="product"
                          />
                          {item.productName}
                        </div>
                      </td>
                      {/* <td style={{ width: "50%", fontSize: 12 }}>
                        <div style={{ maxWidth: 250, wordWrap: "break-word" }}>
                          {item.description}
                        </div>
                      </td> */}
                      <td style={{ width: "10%", fontSize: 12 }}>
                        {item.supplier}
                      </td>
                      <td style={{ width: "10%", fontSize: 12 }}>
                        {item.brand}
                      </td>
                    </tr>
                  ))}
                  {statusMessage && (
                    <tr>
                      <td colSpan={6} className="notable-records">
                        {statusMessage}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                      }}
                    >
                      Items per page: {pageSize}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      fontFamily: "Open Sans",
                    }}
                  >
                    Showing{" "}
                    {Math.min(
                      (currentPage - 1) * pageSize + 1,
                      totalRecordCount
                    )}{" "}
                    - {Math.min(currentPage * pageSize, totalRecordCount)} of{" "}
                    {totalRecordCount} results
                  </Typography>
                  <Box>
                    <Pagination
                      count={count}
                      variant="outlined"
                      color="primary"
                      shape="rounded"
                      onChange={(event, value) => handlePageChange(value, true)}
                      page={currentPage}
                    />
                  </Box>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
