import React from "react";
import "./Home.css";
import Navbar from "../Navbar/Navbar";

const Home = () => {
  return (
    <div className="home-page">
      <div className="navbar">
        <Navbar />
        <div className="header-desc">
          <div className="aquaquickship-title">
            Aqua QuickShip Content Portal
          </div>
          <div className="quickship-img">
            <img
              src={require("../../assets/quickship-map.png")}
              alt="Quick Ship Map"
            />
          </div>
        </div>
      </div>
      <div className="landing-page">
        <h2>Discover</h2>
        <h3>Aqua QuickShip</h3>
        <p className="seamless-desc">
          Aqua QuickShip may be a new brand, but our roots are grounded in over
          15 years of fulfillment experience. Bel-Aqua Pool Supply, Cinderella,
          and Florida Water Products all engaged in fulfillment activities as
          part of their legacy business. Upon joining the Heritage Pool Supply
          Group family, these three separate fulfillment platforms were merged
          into one seamless enterprise incorporating each brand’s best practices
          and offering thousands of products ready for immediate shipment from
          six distribution centers located around the United States with 1-2 day
          shipping time to the majority of the country. The expertise of our
          people combined with the resources to build the best operating and
          shipping platform in existence makes Aqua QuickShip the easy choice to
          meet all of your fulfillment needs.
        </p>
        <p className="seamless-desc">
          The goal of Aqua QuickShip is to provide both our fulfillment partners
          and end users with an experience that is unmatched in the industry.
        </p>
        <h2>AQS Spotlight . . .</h2>
        <h3>What Sets Us Apart</h3>
        <ul>
          <li>
            <span className="list-title">Deep Roots.</span> Aqua Quick Ship is
            proud of its roots in Bel Aqua, Cinderella, and Florida Water
            Products. With over 15 years of experience, we know how to quickly
            ship everything from parts to swimming pools using technology and
            experience to reduce costs and damage claims.
          </li>
          <li>
            <span className="list-title">Entrepreneurial Spirit.</span>{" "}
            Fulfillment has evolved and so have we. Our teams are empowered to
            develop new processes to accelerate transaction speeds and slow down
            freight claims. We are constantly trying to improve the experience
            for both our fulfillment partners and end users so that we are known
            as the best fulfillment partner for your business.
          </li>
          <li>
            <span className="list-title">
              Local Mastery with Nationwide Reach.
            </span>{" "}
            Each of the six Aqua Quick Ship Fulfillment Centers follows the same
            quality control and operational standards, insuring flawless
            execution at the local level. At the same time, the placement of
            these facilities allows shipments to reach end users in the shortest
            timeframe possible.
          </li>
          <li>
            <span className="list-title">Resource-Powered.</span> As part of
            Heritage Pool Supply Group, Aqua Quick Ship has the capital and
            resources to unlock cutting edge technology that improves our
            partners’ experience and makes conducting business easier. Whether
            it be seamless integration with Aqua Quick Ship’s EDI or API feeds
            or utilizing the latest packaging and shipping technology,
            partnering with Aqua Quick Ship allows the resources of Heritage
            Pool Supply Group to go to work for you.
          </li>
          <li>
            <span className="list-title">Customer-First.</span> Aqua Quick
            Ship’s goal is to provide an unmatched customer experience. Real
            time inventory and pricing, quick order processing times, instant
            access to shipping confirmations, and after sale customer support
            are just some of the ways we prioritize the needs of our partners.
          </li>
          <li>
            <span className="list-title">Enduring Vision.</span> This is more
            than business to our team – it is our profession. Our fulfillment
            partners are more than our customers – they are our friends. Our
            goal is to maintain a can-do culture for decades to come and be the
            preferred fulfillment partner in the pool industry.
          </li>
        </ul>
        <div className="seamless-transactions">
          <div className="seamless">
            <h2>Seamless Transactions</h2>
            <p className="seamless-desc">
              Transacting with Aqua QuickShip is a seamless experience. Bel
              Aqua, Cinderella, and Florida Water Products exist under the
              Heritage umbrella as a unified fulfillment platform with
              nationwide reach. This unified platform allows you to:
            </p>
            <ul>
              <li>Obtain real-time inventory and pricing;</li>
              <li>
                Submit your orders knowing our fulfillment mapping software will
                send your order to the distribution center closest to the end
                user, thereby reducing your freight costs and insuring the
                fastest delivery speed;
              </li>
              <li>Receive tracking information instantly;</li>
              <li>Receive Consolidated Invoices;And</li>
              <li>Interact With A Single Point Of Contact.</li>
            </ul>
            <p className="seamless-desc">
              If you are an existing customer, know that you can collaborate
              with the same dedicated teams you have known and trusted for
              years. If you are a new customer, let us show you the Aqua Quick
              Ship difference
            </p>
          </div>
          <div className="quick-ship">
            <h2 className="quick-ship-title">Quick Ship Zones</h2>
            <p className="seamless-desc">
              Harness the power of our six strategic locations: Saginaw (2),
              Harrisburg, Tampa, Ft. Worth, and Las Vegas.
            </p>
            <p className="seamless-desc">
              Our commitment is to efficiency—we aim for nationwide small
              package delivery (via Federal Express, UPS, or the Postal Service)
              within 24 hours of receiving the order. LTL shipments receive
              tracking within 48 hours of receiving the order.
            </p>
            <p className="seamless-desc">
              With our nationwide footprint, shipping lead times are generally
              1-2 days for most of the country:
            </p>
            <div className="quickship-img">
              <img
                src={require("../../assets/quickship-map-desc.png")}
                alt="Quick Ship Map"
              />
            </div>
          </div>
        </div>
        <div className="excellence-heritage">
          <div className="excellence">
            <h2>Excellence</h2>
            <h3>What We Offer</h3>
            <ul>
              <li>
                <span className="list-title">
                  Custom Packaging and Shipping:
                </span>{" "}
                We utilize the latest shipping technology and a Packsize machine
                to make custom-designed boxes that reduce weight and shipping
                dimensions, thereby saving you freight expenses while at the
                same time reducing the risk of damage to the shipment. For LTL
                shipments, we use custom-designed pallets for secure and
                optimized shipping to minimize the risk of freight damage. Our
                shipping procedures have evolved over our 15 years of
                experience. We know how to get goods from Point A to Point B
                safely and securely, thereby increasing customer satisfaction
                and saving you money.
              </li>
              <li>
                <span className="list-title">Inventory Commitment:</span>{" "}
                Extensive stock at each distribution facility insures you will
                find what you need.
              </li>
              <li>
                <span className="list-title">Unbeatable Delivery:</span>{" "}
                Capitalize on our six locations for fast deliveries with lower
                freight costs and benefit from our ambitious quick-ship
                commitment.
              </li>
              <li>
                <span className="list-title">Total Customer Commitment:</span>{" "}
                Trust in Aqua QuickShip’s unique after-sale support for the end
                user – unique to the pool industry – that allows for a
                streamlined, unified point of contact for all queries,
                especially those involving complex LTL shipments. This
                after-sale support reduces the likelihood of customer returns
                and costly charge-backs.
              </li>
              <li>
                <span className="list-title">Smooth Integration:</span>{" "}
                Experience easy onboarding and continuous connectivity via EDI
                or APIs.
              </li>
            </ul>
          </div>
          <div className="heritage-advantage">
            <h2>The Heritage Advantage -</h2>
            <h3>Why Partner with Aqua QuickShip</h3>
            <h3>
              Choose Aqua QuickShip and take your fulfillment business to the
              next level:
            </h3>
            <ul>
              <li>
                <span className="list-title">Nationwide Reach.</span> Strategic
                shipping points insure swift deliveries and significant freight
                savings.
              </li>
              <li>
                <span className="list-title">Inventory Expertise.</span> The
                most sought-after products in stock and ready to ship.
              </li>
              <li>
                <span className="list-title">Budget Friendly.</span> Competitive
                product pricing and carrier rates.
              </li>
              <li>
                <span className="list-title">Rapid Turnaround.</span> Short lead
                times mean product gets out the door to the end user ASAP.
              </li>
              <li>
                <span className="list-title">Unmatched Customer Service.</span>{" "}
                Industry exclusive single point of contact and after-sale end
                user support.
              </li>
              <li>
                <span className="list-title">Strong Manufacturer Ties.</span>{" "}
                Solid relationships with top industry manufacturers means the
                most in-demand products in stock at a fair price.
              </li>
            </ul>
          </div>
        </div>
        <div className="trust-priority">Trust is our priority</div>
      </div>
    </div>
  );
};

export default Home;
