import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Mydownloads.css";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { myConfig } from "../../config.js";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function Mydownloads() {
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const accessToken = cookies.get("accessToken");
  const navigate = useNavigate();
  const apiURL = myConfig.apiUrl;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const myDownloadsData = async () => {
      try {
        const customerId = {
          customerId: cookies.get("customerAccId"),
        };
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        const notificationResponse = await axios.post(
          `${apiURL}/AppNotification/GetAllAppNotification`,
          customerId
        );
        if (notificationResponse.data.appNotificationsList.length > 0) {
          setLoading(false);
          setData(notificationResponse.data.appNotificationsList);
        } else {
          setLoading(false);
          setNoData(true);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    myDownloadsData();
  }, []);

  const downloadProductFn = (res) => {
    notificationUpdateFn(res);
  };

  const downloadAssetsFn = (res) => {
    notificationUpdateFn(res);
  };

  const notificationUpdateFn = async (res) => {
    try {
      const putRequestData = {
        id: res.id,
        customerId: cookies.get("customerAccId"),
      };
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      const updateNotificationResponse = await axios.put(
        `${apiURL}/AppNotification/UpdateAppNotificationStatus`,
        putRequestData
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const convertDate = (x) => {
    const utcTime = x.split(" ")[1];
    const utcDateTimeString = `1970-01-01T${utcTime}Z`;

    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "America/Los_Angeles", // Pacific Time Zone
      hour12: true,
    };

    const pstTime = new Date(utcDateTimeString).toLocaleString(
      "en-US",
      options
    );

    return pstTime;
  };
  const convertDateYear = (x) => {
    const utcDateString = x.split(" ")[0];
    const utcTimex = x.split(" ")[1];
    const utcDateTimeString = `${utcDateString} ${utcTimex} UTC`;

    const utcDate = new Date(utcDateTimeString);
    const pstDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    return pstDate;
  };
  return (
    <div className="mydownloads-page">
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Navbar />
      <Divider
        variant="middle"
        sx={{ marginLeft: 0, marginRight: 0, marginTop: "10px" }}
      />
      <br />
      <div className="mydownloads-page-details">
        <div className="breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => navigate("/home")}
              underline="hover"
              className="homeLink"
              sx={{ cursor: "pointer" }}
            >
              <HomeIcon sx={{ mr: 0.2 }} fontSize="inherit" />
              Home
            </Link>
            <Link underline="hover" className="productLink">
              My Downloads
            </Link>
          </Breadcrumbs>
        </div>
        <div className="mydownloads-title">My Downloads</div>
        <Divider variant="middle" sx={{ marginLeft: 0, marginRight: 0 }} />
        <br />
        <table className="orders-table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Date</th>
              <th>Product List</th>
              <th>Product Assets</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>
                  {/* {moment(item.createdDate).format("MM/DD/YYYY, hh:mm A")}
                  test */}
                  {/* {convertDateYear(item.createdDate)} */}
                  <b>
                    {monthNames[convertDateYear(item.createdDate).getMonth()] +
                      " "}
                  </b>
                  {convertDateYear(item.createdDate).getDate() + ", "}
                  {convertDateYear(item.createdDate).getFullYear() + " "}
                  {convertDate(item.createdDate) + " " + "PST"}
                </td>
                {item.excelProductURL == null ? (
                  <td className="download-btn">
                    <div className="inactive">In Progress</div>
                  </td>
                ) : (
                  <td
                    className="download-btn"
                    data-testid="downloadProductFn"
                    onClick={() => downloadProductFn(item)}
                  >
                    <a
                      href={item.excelProductURL}
                      className="active"
                      download="Download Product Details"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Product Details
                    </a>
                  </td>
                )}

                {item.zipFileURL == null && (
                  <td className="download-btn">
                    <div className="inactive">In Progress</div>
                  </td>
                )}
                {item.zipFileURL === "No Assets" && (
                  <td className="download-btn">
                    <div className="inactive">Assets Not Found</div>
                  </td>
                )}
                {item.zipFileURL && item.zipFileURL != "No Assets" && (
                  <td
                    className="download-btn"
                    data-testid="downloadAssetFn"
                    onClick={() => downloadAssetsFn(item)}
                  >
                    <a
                      href={item.zipFileURL}
                      className="active"
                      download="Download Assets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Assets
                    </a>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {noData && (
          <div className="noRecords" style={{ fontSize: "20px" }}>
            No data found
          </div>
        )}
      </div>
    </div>
  );
}

export default Mydownloads;
