import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import Cookies from "universal-cookie";
import { myConfig } from "../../config.js";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const Login = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const cookies = new Cookies();
  const apiURL = myConfig.apiUrl;
  const hostname = window.location.origin;
  const [loggedIn, setLoggedIn] = useState("Login");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleUsername = (e) => {
    setUsername(e.target.value);
    setErrors("");
    setLoggedIn("Login");
    setIsDisabled(false);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrors("");
    setLoggedIn("Login");
    setIsDisabled(false);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const forgotFn = () => {
    window.location.href = myConfig.forgetPasswordRedirectURL;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      setErrors("All fields are required!");
    } else {
      setLoggedIn("Logging...");
      setIsDisabled(true);
      try {
        const response = await axios.post(`${apiURL}/Login/login`, {
          username,
          password,
        });
        if (response.data) {
          cookies.set("customerAccId", username);
          cookies.set("accessToken", response.data.accessToken);
          localStorage.setItem("auth", true);
          let expTime = new Date();
          expTime.setSeconds(
            expTime.getSeconds() + response.data.expiresIn - 100
          );
          cookies.set("expTime", expTime);
          cookies.set("expSeconds", response.data.expiresIn);
          window.location.href = `${hostname}/`;
        } else {
          window.location.href = `${hostname}/login`;
        }
      } catch (error) {
        if (error) {
          setErrors("Invalid Email or Password");
        }
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-image">
        <img src={require("../../assets/header-logo.png")} alt="header logo" />
      </div>
      <div className="login">
        <div className="welcome-title">Hi, Welcome back!</div>
        <form onSubmit={handleSubmit} className="login-form">
          <div>
            <p className="label">Email</p>
            <input
              autoComplete="on"
              className="input"
              type="email"
              name="email"
              value={username}
              onChange={handleUsername}
            />
          </div>
          <div className="password-label">
            <p className="label">Password</p>
            <p className="forgot-link" onClick={() => forgotFn()}>
              Forgot?
            </p>
            <input
              autoComplete="on"
              className="input"
              type={passwordType}
              name="password"
              value={password}
              onChange={handlePassword}
            />
            <div onClick={togglePassword} className="password-mask">
              {passwordType === "password" ? (
                <VisibilityOffOutlinedIcon sx={{ color: "#696969" }} />
              ) : (
                <VisibilityOutlinedIcon sx={{ color: "#696969" }} />
              )}
            </div>
          </div>

          <label className="checkbox-label">
            <input type="checkbox" />
            Remember Me
          </label>
          <div style={{ color: "Red", marginTop: "20px" }}>{errors}</div>
          <div className="login-btn">
            <button type="submit" disabled={isDisabled}>
              {loggedIn}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
